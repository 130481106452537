<template>
  <CRow>
    <CCol col="12" xl="12">
      
      <CCard>
        <CCardHeader>
          <div class="row align-items-center">
            <div class="col-6">

              <CHeaderNav>

                <CHeaderNavItem class="px-3">

                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('adCampFilter')"
                  >
                    <CIcon
                      v-if="!loading"
                      size="lg"
                      name="cil-filter"
                      class="mr-2"
                    />
                  </button>
                 
                </CHeaderNavItem>

                <CButton
                  class="m-1"
                  color="primary"
                  square
                  size="sm"
                  @click="openModalEvent('report', 'adCampReport', {}, 'Kampanya Durum Raporu')"
                >
                  Kampanya Durum Raporu              
                </CButton>

                <CButton
                  v-if="false"
                  class="m-1"
                  color="warning"
                  square
                  size="sm"
                  @click="openModalEvent('report','adCampExcelReport', {}, 'E-posta Adreslerini Excele Aktar')"
                >
                  E-posta Listesi Al
                </CButton>

              </CHeaderNav>

            </div>

            <div class="col-6 text-right">
              
              <CButton
                color="success"
                square
                size="sm"
                @click="openModalEvent('create','adCampForm', null, 'Yeni Kampanya Ekle')"
              >
                  Kampanya Ekle
              </CButton>
             
            </div>
          </div>
        </CCardHeader>
      
        <CCardBody>
          <CDataTable
            :items="adcamps"
            :fields="fields"
            :items-per-page="10"
            hover
            pagination
            :loading="loading"
            columnFilter
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('update','adCampForm', lastItem, 'Kampanyayı Düzenle ( '+ lastItem.name+' )')"
            v-on:refresh="filterAdCamps(filterParams)"
            sorter
          >

            <template #show_details="{item}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                  size="sm"
                >
                  <CDropdownItem @click="openModalEvent('report','adCampReport', lastItem, 'Kampanya Durum Raporu( '+ lastItem.name+' )')">
                    Kampanya Durum Raporu Al
                  </CDropdownItem>
                  <CDropdownItem @click="openModalEvent('update','adCampForm', lastItem, 'Kampanyayı Düzenle ( '+ lastItem.name+' )')">
                    Kampanyayı Düzenle
                  </CDropdownItem>
                  <CDropdownItem 
                    @click="openModalEvent('delete','confirm', item, 'Kampanya Silme Ekranı ('+ item.name+')', item.name+' isimli kampanyayı silmek üzeresiniz bunu yapmak istediğinizden emin misiniz?')">
                    Kampanyayı Sil
                  </CDropdownItem>
                </CDropdown>
              </td>
            </template>

            <template #createTime="{item}">
              <td> {{ item.createTime && dateFormat(item.createTime) }} </td>
            </template>

            <template #startTime="{item}">
              <td> {{ item.startTime && dateFormat(item.startTime) }} </td>
            </template>

            <template #finishTime="{item}">
              <td> {{ item.finishTime && dateFormat(item.finishTime) }} </td>
            </template>

            <template #adcampType="{item}">
              <td>
                <CBadge :color="getColorFromStr(item.adcampType)"> {{ item.adcampType }} </CBadge>
              </td>
            </template>

          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="crud"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
      :size="size"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="crud"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
      :loading="loading"
      size="lg"
      ref="form"
      :buttonTitle="buttonTitle"
      :noFooter="noFooter"
      :noConfirm="noConfirm"
      :additionalButtons = "additionalButtons"
      :additionalEvent = "additionalEvent"

    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterAdCamps"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>
<script>

import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'
import FilterSidebar from '../components/filterSidebar'
import moment from 'moment'
import { prepareFilterParams } from "@/helpers/custom"


export default {
  name: 'AdCamps',
  components: {
     ConfirmModal, FormModal, FilterSidebar
  },
  data() {
    return {
      additionalButtons: [],
      modalTitle: '',
      actionType: '',
      module: 'marketing',
      form: 'adCampForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openModalConfirm: false,
      openSidebar: false,
      filterForm: 'adCampFilter',
      filterParams: {},
      lastItem: {},
      buttonTitle: 'Kaydet',
      size: 'sm',
      noFooter: false,
      noConfirm: false,
      variants: ['success', 'dark', 'light', 'danger', 'warning', 'info', 'primary', 'secondary' ],
      reportTypes: [
        {label: "Genel Satış", value: "general"},
        {label: "İkincisi %50", value: "2nd50"},
        {label: "3 Al 2 Öde", value: "buy3pay2"},
      ],
      fields: [
        {key: 'show_details', label: 'İşlemler', filter: false, sorter: false},
        {key: 'id', label: 'ID', filter: false, sorter: true},
        {key: 'name', label: 'İsim', filter: false, sorter: true},
        {key: 'adcampType', label: 'Kampanya Tipi', filter: false, sorter: true},
        {key: 'createTime', label: 'Oluşturma Tarihi', filter: false, sorter: true},
        {key: 'startTime', label: 'Başlangıç Tarihi', filter: false, sorter: false},
        {key: 'finishTime', label: 'Bitiş Tarihi', filter: false, sorter: false},
      ],
      activePage: 1,
      pages: 0,
      filters: [
      {
        type: 'listString',
        dataIndex: 'adcampType',
        field: 'adcampType'
      },
      {
        type: 'string',
        dataIndex: 'name',
        field: 'name'
      },
      {
        type: 'date',
        dataIndex: 'startTime',
        field: 'createTime',
        comparison: 'gt'
      },
      {
        type: 'date',
        dataIndex: 'endTime',
        field: 'createTime',
        comparison: 'lt',
      },
    ]
    }
  },
  computed: {
    adcamps(){
      return this.$store.getters.adcamps;
    },
    loading(){
      return this.$store.getters.marketingLoading;
    }
  },
  methods: {
    openModalEvent(actionType, form, data, title, desc){

      this.buttonTitle = "Kaydet"
      this.noFooter = false
      this.noConfirm = false
      this.additionalButtons = []

      if(actionType == 'report'){

        this.noConfirm = true

        if( form == 'adCampReport'){

          this.buttonTitle = "Rapor Getir"
          this.additionalButtons = [{name:'Excel Aktar', color:'warning'}]

          this.$store.commit('adCampReportData', [])

          data.startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
          data.endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');

          this.$store.dispatch('adcampType_list')
          this.$store.dispatch('allCountries_list');

        } else if( form == 'adCampExcelReport') {

          // Yeni Genel Excel Export yapılınca bu scope silinmeli şuan disabled

          this.$store.dispatch('allCountries_list');

          this.buttonTitle = "Excel'e Aktar"

          data.startTime = moment().subtract(1, 'months').format('YYYY-MM-DD');
          data.endTime = moment().format('YYYY-MM-DD');
          data.reportType = 'general'
          data.amount = 1000
          
        } 

      } else {

        if(data && data.startTime){
            data.startTime = moment(data.startTime).format('YYYY-MM-DD HH:mm:ss');
        }

        if(data && data.finishTime){
          data.finishTime = moment(data.finishTime).format('YYYY-MM-DD HH:mm:ss')
        }

      }

      this.confirmSize = 'sm'
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    additionalEvent(){
      this.$refs.form.$refs.customComponent.additionalEvent()
    },

    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
      this.$store.dispatch('adcampType_list')
    },

    getColorFromStr(str){
      let total = 0;

      str.split('').map( s => {
        total += s.charCodeAt(0)
      })

      return this.variants[total % this.variants.length]
    },

    async filterAdCamps(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)
      await this.$store.dispatch('marketingAdCamp_list', formData)
      
      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },

    dateFormat(data){
      return moment(data).format('DD.MM.YYYY')
    },

    rowClicked(item) {
      this.lastItem = item;
    },

    
    async crud(item, actionType) {
      if(actionType == 'report'){

        var months = moment(item.endDate).diff(item.startDate, 'months', true);

        if(moment().diff(item.startDate, 'years', true) > 1){
          const toast = {
            msg: 'Seçtiğiniz başlangıç tarihi en erken bu günden 1 yıl öncesi olabilir. Seçebileceğiniz en erken tarih: ' + 
            moment().subtract(1, 'years').format('DD.MM.YYYY'),
            header: 'Dikkat!',
            color: 'danger'
          }

          this.$store.commit('showToast', toast)
        }else if(months > 1){

          const toast = {
            msg: 'Seçtiğiniz tarih aralığı en fazla 1 ay olabilir. Seçtiğiniz aralık ' + 
            Math.round(months) + ' ay '+  Math.round((months - Math.round(months)) * 30) + ' gün',
            header: 'Dikkat!',
            color: 'danger'
          }

          this.$store.commit('showToast', toast)

        } else {

          if( this.form == 'adCampReport' ) {

            let formData = new FormData();
            formData.append('startTime', item.startDate);
            formData.append('endTime', item.endDate);
            formData.append('reportType', item.reportType);

            if(item.id)
              formData.append('acid', item.id);

            if(item.adcampType && item.adcampType !== '')
              formData.append('acType', item.adcampType);

            if(item.country && item.country !== '' && item.country.value !== null)
              formData.append('countryId', item.country.value);

            await this.$store.dispatch('marketingAdCamp_report', formData)

            if(!(this.$store.getters.adCampReportData.length > 0)) {

              const toast = {
                msg: 'Seçtiğiniz tarih aralığı ilgili kampanyaya ait veri bulunmamaktadır.',
                header: 'Dikkat!',
                color: 'danger'
              }

              this.$store.commit('showToast', toast)
              
            } 
          } else {
              // Yeni Genel Excel Export yapılınca bu scope silinmeli şuan disabled
              let data = {
                responseType: 'blob',
                reportType: item.reportType,
                startTime: item.startDate,
                endTime: item.endDate,
                countryId: item.countryId,
                amount: item.amount
              }

            await this.$store.dispatch('marketingAdCamp_excel', data)

            if(this.$store.getters.marketingStatus) {
              const url = window.URL.createObjectURL(new Blob([this.$store.getters.marketingStatus]));
              const link = document.createElement('a');
              const report = this.reportTypes.find( e => e.value == item.reportType)
              link.href = url;
              link.setAttribute('download', report && report.label +'_' + moment(new Date()).format('DD/MM/YYYY') + '.xls');
              document.body.appendChild(link);
              link.click();
            }
          } 

        }

      } else {

        let formData = new FormData();
        formData.append('actionType', actionType);

        if(item.id)
          formData.append('id',item.id )

        if(actionType == 'update' || actionType == 'create' ){

          formData.append('startTime', item.startTime ? moment(item.startTime).format('YYYY-MM-DD HH:mm') : '' )
          formData.append('endTime', item.finishTime ? moment(item.finishTime).format('YYYY-MM-DD HH:mm') : '')
          formData.append('name', item.name)
          formData.append('adcampType', item.adcampType)

        }
      
        await this.$store.dispatch('marketingAdCamp_action', formData)

        if(this.$store.getters.marketingStatus.success) {
            await this.filterAdCamps(this.filterParams);
            this.openModalEvent()
        }

      }

    }
  },
  async created() {

    await this.filterAdCamps(this.filterParams);
    
  }
}
</script>
